<template>
  <div>
    <location />
    <div class="page wrap-1200">
      <div class="d-flex flex-column">
        <div class="mb-5">
          <h3 class="subtitle">X-GOLF만의 특별한 이벤트, ‘미니게임’</h3>
          <div class="d-flex flex-wrap">
            <div class="w-60 pe-5">
              <p>
                라운딩 진행 중 참가자에게 다양한 돌발 미션을 제공하는 경품
                이벤트로서 X-GOLF만의 특별한 재미 요소입니다.(벙커 칩인, 니어
                핀, 롱기스트 등)
              </p>
              <p>
                각 매장에서 독립적으로 운영할 수 있도록 설계되어 매장 특성에
                따라 차별화된 고객 서비스 제공이 가능하며, 지역 업체와의 연계
                마케팅을 수행할 수 있는 시스템입니다.
              </p>
              <p>
                다수의 후원자 유치를 통해 더욱더 다양한 경품과 재미가 가득한
                이벤트로 발전하고 있습니다.
              </p>
            </div>
            <div class="w-40">
              <img src="/img/minigame_shot1.jpg" class="img-fluid" />
            </div>
          </div>
        </div>
        <hr class="mb-5" />
        <div class="mb-5 mt-4">
          <h3 class="subtitle">미니게임 이벤트 참여 안내</h3>
          <div class="d-flex flex-wrap">
            <div class="w-60 pe-5">
              <div class="box">
                <span>1.</span>
                <p>
                  화면 좌측 하단에 각 홀에 해당하는 미션과 미션 달성 시 지급되는
                  경품 및 벅스에 대한 안내 공지
                </p>
              </div>
              <div class="box">
                <span>2.</span>
                <p>미션 달성 시 스크린 화면 중앙에 축하 메시지 알림</p>
              </div>
              <div class="box">
                <span>3.</span>
                <p>매장 카운터 PC에 미니게임 당첨 메시지 알림</p>
              </div>
              <div class="box">
                <span>4.</span>
                <p>즉석 경품 또는 벅스 제공</p>
              </div>
            </div>
            <div class="w-40">
              <img src="/img/minigame_shot2.jpg" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //wrap-1200 -->
  </div>
</template>

<script>
import location from "@/components/Location";

export default {
  name: "JoinMinigame",
  components: {
    location,
  },
};
</script>
