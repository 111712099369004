<template>
  <div>
    <location />
    <router-view />
    <div class="page p36">
      <div class="wrap-1200">
        <b-form @submit="onSubmit">
          <div class="select-search list mt-0">
            <select
              aria-label=".form-select-md example"
              v-model="searchData.searchtype"
            >
              <option value="1">아이디</option>
              <option value="2">닉네임</option>
              <option value="3">제목</option>
            </select>
            <input
              class="form-control-lg"
              type="text"
              value="Search"
              v-model="searchData.searchname"
            />
            <input
              type="submit"
              class="btn btn-primary btn-lg search"
              value=""
            />
          </div>
        </b-form>
        <div class="row g-0" v-if="data_yn">
          <div class="col-lg-4" :key="index" v-for="(item, index) in items">
            <router-link
              :to="{
                name: 'NetizenLessonView',
                params: { id: item.swingno },
                query: {
                  searchtype: searchData.searchtype,
                  searchname: searchData.searchname,
                },
              }"
              class="d-block"
            >
              <div class="video-box">
                <span class="icon-play"></span>
                <img
                  src="/img/video_img.png"
                  class="d-block w-100"
                  v-if="browser === 'safari'"
                />
                <video
                  :src="item.path + '/' + item.swingmovie"
                  class="d-block w-100"
                  v-else
                ></video>
              </div>

              <div class="bg-white">
                <h5 class="text-truncate">
                  {{ item.title }}
                </h5>
                <p class="text-black">{{ item.nickname }}</p>
                <!-- span class="lh-lg">{{ item.id }}</span -->
                <p>
                  <i class="material-icons-outlined">textsms</i>{{ item.replycnt | comma }}
                </p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="row g-0 text-center mb-1" v-else>
          <div class="col-lg-12">데이터가 없습니다.</div>
        </div>

        <b-pagination-nav
          :link-gen="linkGen"
          :number-of-pages="page_total"
          v-model="searchData.pageno"
          use-router
          aria-label="Page navigation example"
          class=""
          v-if="page_total > 1"
          first-class="prev"
          prev-class="prev"
          next-class="next"
          last-class="next"
          hide-ellipsis
          limit="10"
        >
          <template #first-text>
            <i class="material-icons">keyboard_double_arrow_left</i>
          </template>
          <template #prev-text>
            <i class="material-icons">navigate_before</i>
          </template>
          <template #next-text>
            <i class="material-icons">navigate_next</i>
          </template>
          <template #last-text>
            <i class="material-icons">keyboard_double_arrow_right</i>
          </template>
          <template #page="{ page, active }">
            <a v-if="active">{{ page }}</a>
            <a v-else>{{ page }}</a>
          </template>
        </b-pagination-nav>
      </div>
    </div>
  </div>
</template>

<script>
import ax from "@/api/event";
import location from "@/components/Location";
import "@/filter/common";

export default {
  name: "netizenLesson",
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        searchname: this.$route.query.searchname || "",
        searchtype: parseInt(this.$route.query.searchtype) || 1,
      },
      page_total: 1,
      data_yn: true,
      items: [],
      rows: 8,
      browser: "",
    };
  },
  components: {
    location,
  },
  watch: {
    "$route.query": function () {
      this.searchData.pageno = parseInt(this.$route.query.page) || 1;
      this.get_swinglist();
    },
  },
  methods: {
    get_swinglist() {
      ax.get_swinglist(this.searchData, (flag, data) => {
        if (flag) {
          if (data.swinglistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }
          this.page_total = Math.ceil(data.swinglistcnt / this.rows);
          this.items = data.swinglist;
          // console.log(data.swinglist);
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            searchname: this.searchData.searchname,
            searchtype: this.searchData.searchtype,
          },
        })
        .catch(() => {});
    },
    linkGen(pageNum) {
      return pageNum === 1
        ? `?searchname=${this.searchData.searchname}&searchtype=${this.searchData.searchtype}`
        : `?page=${pageNum}&searchname=${this.searchData.searchname}&searchtype=${this.searchData.searchtype}`;
    },
    check_browser: function () {
      let agent = navigator.userAgent.toLowerCase();
      let isiOSSafari = navigator.userAgent.match(/like Mac OS X/i)
        ? true
        : false;
      if (isiOSSafari) {
        if (agent.indexOf("safari") !== -1) {
          this.browser = "safari";
        }
      }
    },
  },
  created() {
    this.get_swinglist();
  },
  mounted() {
    this.check_browser();
  },
};
</script>

<style scoped>
/*
.page-item.prev i,
.page-item.next i {
  line-height: 40px;
  font-size: 18px !important;
}
*/
</style>