import axios from "@/api/axios";
import store from "@/store";
import _ from "lodash";
import qs from "qs";

// 스윙영상 가지고 오기
const get_swinglist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    swinglistcnt: 0,
    swinglist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/event/swinglist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      pageno: param.pageno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_swinglist", err);
    result(false, err);
  }
};

// 이벤트 당첨내역
const get_eventwinninglist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    eventwinninglistcnt: 0,
    eventwvinninglist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/event/minigamewinninglist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      pageno: param.pageno,
      type: param.type,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_eventwinninglist", err);
    result(false, err);
  }
};

// 이벤트 내역
const get_eventlist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    eventlistcnt: 0,
    eventlist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/event/minigamelist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      pageno: param.pageno,
      type: param.type,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_eventlist", err);
    result(false, err);
  }
};

// 스윙보기
const get_swingview = async (swingno, result) => {
  let finalData = {
    code: "",
    message: "",
    swingview: null,
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/event/swingview/" + swingno,
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_swinglist", err);
    result(false, err);
  }
};

// 스윙댓글리스트
const get_swingreplylist = async (swingno, pageno, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    swingreplylistcnt: 0,
    swingreplylist: [],
  };
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/event/swingreplylist/" + swingno,
    params: {
      pageno: pageno,
    },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_swingreplylist", err);
    result(false, err);
  }
};

// 스윙댓글등록 등록
const set_swingreplywrite = async (swingno, text, result) => {
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/event/swingreplywrite/" + swingno,
    headers: { Authorization: "Bearer " + token },
    data: qs.stringify({
      text: text,
    }),
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data.message);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("set_swingreplywrite", err);
    result(false, err);
  }
};

// 스윙댓글등록 삭제
const set_swingreplydrop = async (swingno, replyno, result) => {
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "DELETE",
    url:
      process.env.VUE_APP_API_URL +
      "/postact/swingreplydrop/" +
      swingno +
      "/" +
      replyno,
    headers: { Authorization: "Bearer " + token },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data.message);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("set_swingreplydrop", err);
    result(false, err);
  }
};

export default {
  get_swinglist,
  get_eventwinninglist,
  get_eventlist,
  get_swingview,
  get_swingreplylist,
  set_swingreplywrite,
  set_swingreplydrop,
};
