<template>
  <div>
    <location />

    <!-- <div class="p39 page"> -->
    <div id="brs" class="page wrap-1200">
      <div class="wrap-1200">
        <div class="city-search">
          <b-form @submit="onSubmit">
            <select
              class="border-right-0"
              aria-label=".border-right-0 example"
              name="searchtype"
              v-model="searchData.searchtype"
            >
              <option value="1">미니게임명</option>
              <option value="2">매장명</option>
              <option value="3">코스명</option>
            </select>
            <select
              class="border-right-0"
              aria-label=".border-right-0 example"
              name="type"
              v-model="searchData.type"
            >
              <option value="0">전체</option>
              <option value="1">홀인원</option>
              <option value="2">알바트로스</option>
              <option value="3">이글-파5</option>
              <option value="4">버디-파5</option>
              <option value="5">파-파5</option>
              <option value="6">이글-파4</option>
              <option value="7">버디-파4</option>
              <option value="8">파-파4</option>
              <option value="9">롱기스트</option>
              <option value="10">니어 핀</option>
            </select>
            <input
              type="text"
              class="form-control-lg"
              name="searchname"
              v-model="searchData.searchname"
            />
            <input
              class="btn btn-primary btn-lg search"
              type="submit"
              value=""
            />
          </b-form>
        </div>
      </div>
      <!--       <div class="filter-group row mb-lg-3">
        <div class="col-sm-auto mb-sm-0 mb-2 col-12 col-md-3">
          <b-form @submit="onSubmit">
            <div class="d-flex">
              <select
                class="w-auto me-2"
                aria-label=".border-right-0 example"
                name="searchtype"
                v-model="searchData.searchtype"
              >
                <option value="1">미니게임명</option>
                <option value="2">매장명</option>
                <option value="3">코스명</option>
              </select>
              <select
                class="w-auto me-2"
                aria-label=".border-right-0 example "
                name="type"
                v-model="searchData.type"
              >
                <option value="0">전체</option>
                <option value="1">홀인원</option>
                <option value="2">알바트로스</option>
                <option value="3">이글-파5</option>
                <option value="4">버디-파5</option>
                <option value="5">파-파5</option>
                <option value="6">이글-파4</option>
                <option value="7">버디-파4</option>
                <option value="8">파-파4</option>
                <option value="9">롱기스트</option>
                <option value="10">니어 핀</option>
              </select>

            </div>
          </b-form>
        </div>

        <div class="col-sm-auto mb-sm-0 mb-2 col-12 col-md-6">
          <div class="input-group flex-nowrap">
                <input
                  type="text"
                  class="form-control text-truncate"
                  placeholder="검색어를 입력하세요."
                  aria-label="검색어를 입력하세요."
                  aria-describedby="button-addon"
                  name="searchname"
                  v-model="searchData.searchname"
                />
                <button
                  class="btn btn-primary btn-search"
                  type="submit"
                  value=""
                ></button>
          </div>
        </div>

      </div> -->

<!--       <table
        class="board table wrap-1200"
        id="dataTable"
        width="100%"
        cellspacing="0"
      > -->
      <table class="table board">
      <thead>
          <tr>
            <!-- <th class="col-auto d-none d-lg-table-cell">No.</th> -->
            <th class="col-auto d-lg-table-cell">제목</th>
            <th class="col-auto d-none d-lg-table-cell">구분</th>
            <th class="col-auto d-none d-lg-table-cell">기간</th>
            <th class="col-auto d-none d-lg-table-cell">코스</th>
            <th class="col-auto d-none d-lg-table-cell">홀</th>
            <th class="col-auto d-none d-lg-table-cell">벅스</th>
            <th class="col-auto d-lg-table-cell">매장</th>
            <th class="col-auto d-none d-lg-table-cell">상태</th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="data_yn">
          <template v-for="(item, index) in items">
            <tr class="btn1" @click="toggle_tr(index)" :key="index">
              <!-- <td class="col-auto d-none d-lg-table-cell">{{ item.no }}</td> -->
              <td class="col-auto d-lg-table-cell">{{ item.title }}</td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ event_type(item.type) }}
              </td>
              <td class="col-auto d-none d-lg-table-cell text-date">
                {{
                  $moment(item.startdate).format("YYYY-MM-DD") +
                  "~" +
                  $moment(item.enddate).format("YYYY-MM-DD")
                }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.coursename }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.hole + " 홀" }}
              </td>
              <td class="col-auto d-none d-lg-table-cell point">
                {{ item.point | comma }} B
              </td>
              <td class="col-auto d-lg-table-cell">
                {{ item.shopname }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ change_status(item.status) }}
              </td>
              <td class="td-arrow">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="col-auto d-lg-none d-md-tabel-cell"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td class="align-left" colspan="8">
                <div class="layer1">
                  <!-- <div class="row g-0">
                    <div class="col-6">No.</div>
                    <div class="col-6">{{ item.no }}</div>
                  </div> -->
                  <div class="row g-0">
                    <div class="col">구분</div>
                    <div class="col-9">{{ event_type(item.type) }}</div>
                  </div>
                  <div class="row g-0">
                    <div class="col">기간</div>
                    <div class="col-9 date">
                      {{
                        $moment(item.startdate).format("YYYY-MM-DD") +
                        "~" +
                        $moment(item.enddate).format("YYYY-MM-DD")
                      }}
                    </div>
                  </div>
                  <div class="row g-0">
                    <div class="col">코스</div>
                    <div class="col-9">
                      {{ item.coursename }}
                    </div>
                  </div>

                  <div class="row g-0">
                    <div class="col">홀</div>
                    <div class="col-9">
                      {{ item.hole + "H" }}
                    </div>
                  </div>

                  <div class="row g-0">
                    <div class="col">벅스</div>
                    <div class="col-9 point">{{ item.point | comma }}B</div>
                  </div>

                  <div class="row g-0">
                    <div class="col">상태</div>
                    <div class="col-9">{{ change_status(item.status) }}</div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8" class="text-center">데이터가 없습니다.</td>
          </tr>
        </tbody>
      </table>
      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation example"
        class="mt-3"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/event";
import { myMixin } from "@/mixin/myMixin";
import "@/filter/common";

export default {
  name: "MiniGame",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        searchname: this.$route.query.searchname || "",
        searchtype: parseInt(this.$route.query.searchtype) || 1,
        type: parseInt(this.$route.query.type) || 0,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      eventlistcnt: 0,
      view_hide: null,
    };
  },
  watch: {
    "$route.query": function () {
      this.searchData.pageno = parseInt(this.$route.query.page) || 1;
      this.get_eventlist();
    },
  },
  methods: {
    get_eventlist() {
      ax.get_eventlist(this.searchData, (flag, data) => {
        if (flag) {
          if (data.eventlistcnt <= 0) {
            this.data_yn = false;
            // return false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.eventlistcnt;
          this.page_total = Math.ceil(data.eventlistcnt / this.rows);
          this.items = data.eventlist;
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router.push({
        query: {
          searchname: this.searchData.searchname,
          searchtype: this.searchData.searchtype,
          type: this.searchData.type,
        },
      });
    },
    linkGen(pageNum) {
      return pageNum === 1
        ? `?searchname=${this.searchData.searchname}&searchtype=${this.searchData.searchtype}&type=${this.searchData.type}`
        : `?page=${pageNum}&searchname=${this.searchData.searchname}&searchtype=${this.searchData.searchtype}&type=${this.searchData.type}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_eventlist();
  },
  mixins: [myMixin],
};
</script>
